import React, { useMemo } from 'react'
import styled from 'styled-components'
import Panel from '../Panel'
import { AutoColumn } from '../Column'
import { RowFixed } from '../Row'
import { TYPE } from '../../Theme'
import { usePairData } from '../../contexts/PairData'
import { formattedNum } from '../../utils'

const PriceCard = styled(Panel)`
  position: absolute;
  right: -250px;
  width: 250px;
  top: -20px;
  height: fit-content;
  background-color: ${({ theme }) => theme.bg1};
`

function formatPercent(rawPercent) {
  if (rawPercent < 0.01) {
    return '<1%'
  } else return parseFloat(rawPercent * 100).toFixed(0) + '%'
}

export default function UniPrice() {
  const xUSDTPair = usePairData('0x2f9423c785734f27b5cad494948e98c4e4e8a4e0')
  const USPLUSPair = usePairData('0xac95052159f291bcb89a57f6adc1b90aec3845b6')

  const totalLiquidity = useMemo(() => {
    return xUSDTPair && USPLUSPair
      ? parseFloat(xUSDTPair.trackedReserveUSD) + parseFloat(USPLUSPair.trackedReserveUSD)
      : 0
  }, [xUSDTPair, USPLUSPair])

  const xUSDTPerEth = xUSDTPair ? parseFloat(xUSDTPair.token1Price).toFixed(4) : '-'
  const USPLUSPerEth = USPLUSPair ? parseFloat(USPLUSPair.token1Price).toFixed(4) : '-'

  return (
    <PriceCard>
      <AutoColumn gap="10px">
        <RowFixed>
          <TYPE.main>xUSDT/XDC: {formattedNum(xUSDTPerEth, true)}</TYPE.main>
          <TYPE.light style={{ marginLeft: '10px' }}>
            {/*{xUSDTPair && totalLiquidity ? formatPercent(xUSDTPair.trackedReserveUSD / totalLiquidity) : '-'}*/}
            100%
          </TYPE.light>
        </RowFixed>
        {/*<RowFixed>*/}
        {/*  <TYPE.main>US+/XDC: {formattedNum(USPLUSPerEth, true)}</TYPE.main>*/}
        {/*  <TYPE.light style={{ marginLeft: '10px' }}>*/}
        {/*    {USPLUSPair && totalLiquidity ? formatPercent(USPLUSPair.trackedReserveUSD / totalLiquidity) : '-'}*/}
        {/*  </TYPE.light>*/}
        {/*</RowFixed>*/}
      </AutoColumn>
    </PriceCard>
  )
}
