import React from 'react'
import 'feather-icons'
import styled from 'styled-components'
import { Text } from 'rebass'
import { AlertTriangle } from 'react-feather'
import { RowFixed } from '../Row'
import { AutoColumn } from '../Column'
const WarningWrapper = styled.div`
  border-radius: 20px;
  border: 1px solid #f82d3a;
  background-color: ${({ theme }) => theme.bg1};
  padding: 1rem;
  color: #f82d3a;
  margin: 0 2rem 2rem 2rem;
  /* position: relative; */
  position: sticky;
  top: 2rem;
  z-index: 10;

  @media screen and (max-width: 800px) {
    width: 80% !important;
    margin-left: 5%;
  }
`

const StyledWarningIcon = styled(AlertTriangle)`
  min-height: 20px;
  min-width: 20px;
  stroke: red;
`

export function BlockWarning({ blockedInfo }) {
  return (
    <WarningWrapper>
      <AutoColumn gap="4px">
        <RowFixed>
          <StyledWarningIcon />
          <Text fontWeight={600} lineHeight={'145.23%'} ml={'10px'}>
            SCAM ALERT
          </Text>
        </RowFixed>
        <div>
          <Text fontWeight={500} lineHeight={'145.23%'} mt={'10px'}>
            {blockedInfo.description}
          </Text>
        </div>
      </AutoColumn>
    </WarningWrapper>
  )
}
