import React from 'react'
import styled from 'styled-components'
import { SimpleSocialLinks } from '../../constants/social'
import { socialMediaIcons } from './icons'

const SocialLinkWrapper = styled.a`
  > svg {
    color: ${({ theme }) => theme.text1};

    :hover {
      transition: all 0.1s;
      color: ${({ theme, color }) => color ?? theme.primary1 ?? 'black'};
      transform: scale(1.15);
    }
  }
`

export function SimpleSocialLink({ color, href, icon, size = '2rem' }) {
  const Icon = socialMediaIcons[icon] ?? socialMediaIcons[SimpleSocialLinks.WEBSITE]

  return (
    <SocialLinkWrapper href={href} target="_blank" color={color}>
      <Icon
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        aria-hidden="true"
        role="img"
        preserveAspectRatio="xMidYMid meet"
        width={size}
        height={size}
      />
    </SocialLinkWrapper>
  )
}
