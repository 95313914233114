import React from 'react'
import { SimpleSocialLinks } from '../../constants/social'
import { ReactComponent as WebsiteSVG } from '../../assets/social/website.svg'
import { ReactComponent as GithubSVG } from '../../assets/social/github.svg'
import { ReactComponent as TwitterSVG } from '../../assets/social/twitter.svg'
import { ReactComponent as RedditSVG } from '../../assets/social/reddit.svg'
import { ReactComponent as TelegramSVG } from '../../assets/social/telegram.svg'
import { ReactComponent as DiscordSVG } from '../../assets/social/discord.svg'
import { ReactComponent as WhitepaperSVG } from '../../assets/social/whitepaper.svg'
import { ReactComponent as BlogSVG } from '../../assets/social/blog.svg'
import { ReactComponent as ChatSVG } from '../../assets/social/chat.svg'
import { ReactComponent as FacebookSVG } from '../../assets/social/facebook.svg'
import { ReactComponent as ForumSVG } from '../../assets/social/forum.svg'
import { ReactComponent as GitterSVG } from '../../assets/social/gitter.svg'
import { ReactComponent as InstagramSVG } from '../../assets/social/instagram.svg'
import { ReactComponent as LinkedinSVG } from '../../assets/social/linkedin.svg'
import { ReactComponent as SlackSVG } from '../../assets/social/slack.svg'
import { ReactComponent as YoutubeSVG } from '../../assets/social/youtube.svg'
import { ReactComponent as MediumSVG } from '../../assets/social/medium.svg'
import { ReactComponent as CoinMarketCapSVG } from '../../assets/social/coinmarketcap.svg'

export const socialMediaIcons = {
  [SimpleSocialLinks.WEBSITE]: (props) => <WebsiteSVG viewBox="0 0 24 24" {...props} />,
  [SimpleSocialLinks.GITHUB]: (props) => <GithubSVG viewBox="0 0 24 24" {...props} />,
  [SimpleSocialLinks.TWITTER]: (props) => <TwitterSVG viewBox="0 0 24 24" {...props} />,
  [SimpleSocialLinks.REDDIT]: (props) => <RedditSVG viewBox="0 0 24 24" {...props} />,
  [SimpleSocialLinks.DISCORD]: (props) => <DiscordSVG viewBox="0 0 24 24" {...props} />,
  [SimpleSocialLinks.WHITEPAPER]: (props) => <WhitepaperSVG viewBox="0 0 24 24" {...props} />,
  [SimpleSocialLinks.BLOG]: (props) => <BlogSVG viewBox="0 0 122.88 95.51" {...props} />,
  [SimpleSocialLinks.CHAT]: (props) => <ChatSVG width="800px" height="800px" {...props} />,
  [SimpleSocialLinks.FACEBOOK]: (props) => <FacebookSVG viewBox="0 0 24 24" {...props} />,
  [SimpleSocialLinks.FORUM]: (props) => <ForumSVG viewBox="0 0 115.99 122.88" {...props} />,
  [SimpleSocialLinks.GITTER]: (props) => <GitterSVG viewBox="0 0 48 48" {...props} />,
  [SimpleSocialLinks.INSTAGRAM]: (props) => <InstagramSVG viewBox="0 0 24 24" {...props} />,
  [SimpleSocialLinks.LINKED_IN]: (props) => <LinkedinSVG viewBox="0 0 24 24" {...props} />,
  [SimpleSocialLinks.SLACK]: (props) => <SlackSVG viewBox="0 0 24 24" {...props} />,
  [SimpleSocialLinks.YOUTUBE]: (props) => <YoutubeSVG viewBox="0 0 24 24" {...props} />,
  [SimpleSocialLinks.MEDIUM]: (props) => <MediumSVG viewBox="0 0 24 24" {...props} />,
  [SimpleSocialLinks.COIN_MARKET_CAP]: (props) => <CoinMarketCapSVG viewBox="0 0 76.52 77.67" {...props} />,

  ['telegram']: (props) => <TelegramSVG viewBox="0 0 24 24" {...props} />,
}

export function SocialIcon({ type, ...props }) {
  const Icon = socialMediaIcons[type] ?? socialMediaIcons[SimpleSocialLinks.WEBSITE]

  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      aria-hidden="true"
      role="img"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    />
  )
}
