import React from 'react'
import styled from 'styled-components'
import { SocialIcon } from './icons'

const DropdownSocialLinkWrapper = styled.div`
  position: relative;

  &:hover {
    ul.submenu {
      display: block;
      z-index: 10;
    }
  }

  > svg {
    color: ${({ theme }) => theme.text1};

    :hover {
      transition: all 0.1s;
      color: ${({ theme }) => theme.primary1};
      transform: scale(1.15);
    }
  }
`

/* items:[{name: string, url: string}] */
export function DropdownSocialLink({ items, icon, size = '2rem' }) {
  return (
    <DropdownSocialLinkWrapper>
      <SocialIcon type={icon} width={size} height={size} />
      <Submenu items={items} />
    </DropdownSocialLinkWrapper>
  )
}

const SubmenuWrapper = styled.ul`
  --triangle-size: 10px;

  list-style: none;
  margin: 0;
  padding: 0;
  border-radius: 8px;

  text-transform: none;
  display: none;
  position: absolute;
  top: calc(100% + var(--triangle-size));

  color: ${({ theme }) => theme.text1};
  background-color: ${({ theme }) => theme.bg1};
  border: 1px solid ${({ theme }) => theme.bg3};

  &:after,
  &:before {
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid ${({ theme }) => theme.bg1};
    top: -10px;
    content: '';
    left: 15%;
    margin-left: -10px;
    position: absolute;
  }

  /* Styling for second triangle (border) */
  &:before {
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-bottom: 11px solid;
    border-bottom-color: ${({ theme }) => theme.bg3}; /* Can't be included in the shorthand to work */
    top: -12px;
    margin-left: -11px;
  }
`

function Submenu({ items }) {
  return (
    <SubmenuWrapper className="submenu">
      {items.map((item) => (
        <SubmenuItem key={`submenu-item-${item.name}`} title={item.name} href={item.url} />
      ))}
    </SubmenuWrapper>
  )
}

const SubmenuItemWrapper = styled.li`
  padding: 0.5rem 1rem;

  &:hover {
    background-color: ${({ theme }) => theme.bg2};
  }
`

const SubmenuLinkWrapper = styled.a`
  display: block;
  width: 100%;
  height: 100%;

  color: currentColor;
`

function SubmenuItem({ title, href }) {
  return (
    <SubmenuItemWrapper>
      <SubmenuLinkWrapper href={href} target={'_blank'}>
        {title}
      </SubmenuLinkWrapper>
    </SubmenuItemWrapper>
  )
}
