export const SimpleSocialLinks = {
  WEBSITE: 'website',
  GITHUB: 'github',
  TWITTER: 'twitter',
  MEDIUM: 'medium',
  DISCORD: 'discord',
  WHITEPAPER: 'whitepaper',
  COIN_MARKET_CAP: 'coinmarketcap',
  FACEBOOK: 'facebook',
  GITTER: 'gitter',
  INSTAGRAM: 'instagram',
  LINKED_IN: 'linkedin',
  YOUTUBE: 'youtube',
  REDDIT: 'reddit',
}

//structure: {[ base-name ]: { [ info-json-name ]: name-in-dropdown }}
export const GroupedSocialLinks = {
  telegram: {
    telegramAnnouncement: 'Announcement',
    telegramChat: 'Chat',
    telegramSupport: 'Support',
  },
}
