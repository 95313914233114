import { useEffect, useState } from 'react'
import { TOKEN_LIST_REPO } from '../constants'
import { GroupedSocialLinks } from '../constants/social'
import { isAddress } from '../utils'

/**
 * @typedef SocialLink
 * @type {object}
 * @property {string} name
 * @property {string} url
 */

/**
 * @param {string} address
 * @param {boolean} isWhiteListed
 *
 */
export function useTokenSocialInfos(address, isWhiteListed) {
  const [socialInfo, setSocialInfo] = useState([null, null])

  useEffect(() => {
    if (!address || !isWhiteListed) {
      setSocialInfo([null, null])
      return
    }
    ;(async function () {
      const resp = await fetch(tokenInfoUrl(isAddress(address)))
      if (!resp.ok) {
        console.error('Error while fetching token info')
      }

      const fullInfo = await resp.json()

      const { simpleLinks, groupedLinks } = parseLinks(fullInfo.links)
      setSocialInfo([simpleLinks, groupedLinks])
    })()
  }, [address])

  return socialInfo
}

const tokenInfoUrl = (address) => `${TOKEN_LIST_REPO}/assets/${address}/info.json`

/**
 * grouped links: { [baseName]: {
 *    [subName]: {
 *        name: string,
 *        url: string
 * }}}
 */
function parseLinks(links) {
  const simpleLinks = []
  const groupedLinks = {}

  const groupSocialLinkBaseNames = Object.keys(GroupedSocialLinks)
  for (const link of links) {
    for (const baseName of groupSocialLinkBaseNames) {
      const groupedSocials = Object.keys(GroupedSocialLinks[baseName])
      if (link.name.startsWith(baseName) && groupedSocials.includes(link.name)) {
        if (!groupedLinks[baseName]) groupedLinks[baseName] = []

        groupedLinks[baseName] = [
          ...groupedLinks[baseName],
          {
            name: GroupedSocialLinks[baseName][link.name],
            url: link.url,
          },
        ]
      } else {
        simpleLinks.push({
          name: link.name,
          url: link.url,
        })
      }
    }
  }

  return { simpleLinks, groupedLinks }
}
